<template>
	<div>
		<div class="layout-menu-container">
			<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
		</div>
	</div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
	props: {
		model: Array,
	},
	data() {
		return {
		};
	},
	methods: {
		onMenuItemClick(event) {
			this.$emit("menuitem-click", event);
		},
	},
	components: {
		AppSubmenu: AppSubmenu,
	},
};
</script>

<style scoped>
.d-dropdown {
	position: fixed;
	bottom: 30px;
	left: 50px;
	width: 150px;
}
</style>
