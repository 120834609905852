<template>
	<div class="p-d-flex layout-topbar">
		<div class="logos">
			<router-link to="/">
				<img alt="Logo VTrace" height="37px" :src="logoVTrace" />
			</router-link>
			<img alt="Logo Optel" height="35px" :src="logoUnicef" />
		</div>
		<div class="p-ml-auto p-grid p-align-center p-mt-1">

		</div>
		<div class="p-ml-auto layout-topbar-icons">
			<AppProfile />
		</div>
	</div>
</template>

<script>
import AppProfile from "./AppProfile.vue";

export default {
	components: {
		AppProfile: AppProfile,
	},
	data() {
		return {
		};
	},
	computed: {
		logoOptel() {
			return "assets/layout/images/new_logo_optel.svg";
		},
		logoBV() {
			return "assets/bureau_veritas_white.svg";
		},
		logoUnicef() {
			return "assets/UNICEF_Logo.png";
		},
		logoVTrace() {
			return "assets/v-trace-horizontal.svg";
		},
	},
	methods: {
		onMenuToggle(event) {
			this.$emit("menu-toggle", event);
		},
	},
};
</script>

<style lang="scss" scoped>
.search-box {
	width: 40vw;
	max-width: 500px;
	opacity: 0.8;
	color: #444444;
}

.search-box-icon {
	color: #444444;
	z-index: 1500;
}

.o-m-0 {
	margin: 0px;
}

.o-mr-2 {
	margin-right: 10px;
}

.layout-topbar img {
	margin-right: 30px;
}

.layout-topbar .logos {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
}
</style>
