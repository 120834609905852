<template>
	<div>
        <div class="splash-screen">
            <div class="splash-container">{{$auth.twofa}}
                <div class="splash-double-bounce1"></div>
                <div class="splash-double-bounce2"></div>
            </div>
        </div>
        <Dialog :header="$t('2FA')" :visible.sync="display2FADialog" :dismissableMask="false" :style="{width: '600px'}" :modal="false" :closable="false">
            {{$t('Please input the 6 digit 2FA code in the field bellow')}}
            <div class="p-field">
                <div class="p-inputgroup">
                    <Button class="p-button-info" :label="$t('Ok')" @click="validate2FA()" :disabled="!validationCode" />
                    <InputText id="smsnumber" type="text" v-model="validationCode" />
                </div>            
            </div>
        </Dialog>
	</div>
</template>

<script>
import ApiService from '../service/ApiService';

export default {
    name: "TwoFA",
	data() {
		return {
            api: null,
            validationCode: '',
            code: '',
            display2FADialog: false
		};
	},
    created() {
        this.api = new ApiService(this.$auth.accessToken);
        this.$auth.eventBus.$on("access_token_refreshed", () => {
                this.api.updateToken(this.$auth.accessToken)
        });
        this.doConfirm2FA();
    },
    destroyed(){
        this.$auth.eventBus.$off("access_token_refreshed");
    },
	methods: {
        doConfirm2FA() {
            this.code = "";
            for (let i=0; i<6; i++) {
                this.code += Math.random().toString()[3];
            }
            this.api.postToEp(this.$auth.twofa.method, 
            {
                phoneNumber: this.$auth.twofa.number,
                message: this.$t('Your V-Trace platform verification code : ') + this.code,
                text: this.$t('Your V-Trace platform verification code : ') + this.code,
                subject: 'V-Trace 2FA',
                to: this.$auth.user.email,
                from: 'noreply@optel.app'
            })
            this.display2FADialog = true;
        },
        validate2FA() {
            if (this.code === this.validationCode) {
                sessionStorage.twofa = true;
                this.$auth.twofa.status = true;
                location.reload();
            }
        }
	},
	components: {
	},
};
</script>

<style scoped>
</style>
