import { render, staticRenderFns } from "./AppMenu.vue?vue&type=template&id=6a5f9dec&scoped=true&"
import script from "./AppMenu.vue?vue&type=script&lang=js&"
export * from "./AppMenu.vue?vue&type=script&lang=js&"
import style0 from "./AppMenu.vue?vue&type=style&index=0&id=6a5f9dec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5f9dec",
  null
  
)

export default component.exports