<template>
	<div>
		<div v-if="!appAccess">
			<div class="splash-screen">
				<div class="splash-container">
					<div class="splash-double-bounce1"></div>
					<div class="splash-double-bounce2"></div>
				</div>
			</div>
		</div>
		<div v-else-if="$auth.twofa && $auth.twofa.value && !$auth.twofa.status">
			<TwoFA />
		</div>
		<div v-else :class="containerClass" @click="onWrapperClick">
			<AppTopBar @menu-toggle="onMenuToggle" v-if="showTopBar && $auth.isAuthenticated" />

			<transition name="layout-sidebar">
				<div :class="sidebarClass" @click="onSidebarClick" v-if="isSidebarVisible() && $auth.isAuthenticated && menu.length">
					<AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
				</div>
			</transition>

			<div class="layout-main">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import AppTopBar from "./layout/AppTopbar.vue";
import AppMenu from "./layout/AppMenu.vue";
import ApiService from "./service/ApiService";
import {hasAccess} from './auth/CheckRoles';
import TwoFA from "./pages/2FA.vue";

export default {
	data() {
		return {
			apiService: null,
			staticMenuInactive: true,
			mobileMenuActive: false,
			menu: [],
		};
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		},
        "$auth.isAuthenticated": function () {
            this.checkAuthentication();
        },
	},
	created() {
		this.apiService = new ApiService();
        this.$auth.eventBus.$on("authenticated", this.checkAuthentication);

        this.menu = this.parseMenuEntries(this.$router.options.routes);
		if (this.menu.length) {
			this.staticMenuInactive = false;
		}
	},
	destroyed() {
        this.$auth.eventBus.$off("authenticated");
	},
	mounted() {
        this.checkAuthentication();
	},
	updated() {
        this.checkAuthentication();
	},
	methods: {
		onWrapperClick() {
			if (!this.menuClick) {
				this.mobileMenuActive = false;
			}

			this.menuClick = false;
		},
		onMenuToggle() {
			this.menuClick = true;

			if (this.isDesktop()) {
				// Open / Close menu is disable since there is some issues for mapbox and the flows.
				this.staticMenuInactive = false; // !this.staticMenuInactive;
			} else {
				this.mobileMenuActive = !this.mobileMenuActive;
			}

			event.preventDefault();
		},
		onSidebarClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (event.item && !event.item.items) {
				this.mobileMenuActive = false;
			}
			this.onMenuToggle();
		},
		addClass(element, className) {
			if (element.classList) element.classList.add(className);
			else element.className += " " + className;
		},
		removeClass(element, className) {
			if (element.classList) element.classList.remove(className);
			else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isSidebarVisible() {
			if (this.isDesktop()) {
				return !this.staticMenuInactive;
			} else {
				return true;
			}
		},
        checkAuthentication() {
            this.isAuthenticated = this.$auth.isAuthenticated;
            const isLoginPage = window.location.href.indexOf("login") > 0;
            if (this.isAuthenticated) {
                this.waitingForAuth = false;
            }
            else if (!this.isAuthenticated && this.$auth.twofa.value && !this.$auth.twofa.status) {
                this.waitingForAuth = true;

				this.$router.push("2fa");
            }
            else if (!this.isAuthenticated && !isLoginPage && !this.waitingForAuth) {
                this.waitingForAuth = true;

                // If the user identity hasn't filled in after a second or so, go to the login page
                setTimeout(() => {
                    this.isAuthenticated = this.$auth.isAuthenticated;
                    this.waitingForAuth = false;
                    if (!this.isAuthenticated) {
                        this.$router.replace({ name: "login" });
                    }
                }, 1000);
            }
        },
		parseMenuEntries(routes) {
			let mes = [];
			routes.forEach(r => {
				if(r.showInMenu && hasAccess(
						r.requiredClient ? this.$auth.user.roles[r.requiredClient]?.roles : this.$auth.user.allRoles, 
						r.requiredRoles, 
						r.requiredMode
					)) {
					let me = null;
					if (r.external) {
						me = {
							label: this.$t(r.name),
							url: r.external.url,
							target: r.external.target
						};
					}
					else {
						me = {label: this.$t(r.name)};
						if (r.type === 'link') me.to = r.path;
					}
					if (r.children) {
						me.items = this.parseMenuEntries(r.children);
					}
					mes.push(me);
				}
			});
			return mes;
		}
	},
	computed: {
		appAccess() {
			return true;
		},
		containerClass() {
			return [
				"layout-wrapper",
				"layout-static",
				{
					"layout-static-sidebar-inactive": this.staticMenuInactive,
					"layout-mobile-sidebar-active": this.mobileMenuActive,
				},
			];
		},
		sidebarClass() {
			return ["layout-sidebar"];
		},
		showTopBar() {
			return this.$route.query.iframe ? false : true;
		},
	},
	beforeUpdate() {
		if (this.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
		else this.removeClass(document.body, "body-overflow-hidden");
	},
	components: {
		AppTopBar: AppTopBar,
		AppMenu: AppMenu,
		TwoFA
	},
};
</script>

<style lang="scss">
@import "./App.scss";
</style>

<style scoped>
.layout-header {
	display: block;
	position: fixed;
	z-index: 999;
	background: white;
	height: 85px;
	width: 100%;
}
.layout-header > img {
	padding-left: 56px;
	padding-top: 23px;
}
.help-icon {
	cursor: pointer;
	font-size: 50px;
	position: fixed;
	bottom: 40px;
	right: 40px;
	background-color: #ffffff;
	color: #223041;
	border-radius: 49px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
}
</style>
