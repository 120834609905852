<template>
    <div class="layout-top-user" aria:haspopup="true" aria-controls="overlay_panel">
        <router-link to="/account">	
            <div class="user-wrapper p-grid p-align-center p-mt-1 p-mr-2">
                <div class="user-portrait" :style="cssImage">
                    <i class="pi pi3em pi-user o-user-ph"></i>
                </div>
                <span class="username">{{displayNameComputed}}</span>
            </div>
        </router-link>
        <div class="signout-wrapper" @click="signout">
            <svg xmlns="http://www.w3.org/2000/svg" width="21.84" height="21.812" viewBox="0 0 21.84 21.812">
                <g id="Composant_8_1" data-name="Composant 8 – 1" transform="translate(.499 .5)">
                    <path id="Tracé_175" d="M249.656 160.773v-8.057a2.225 2.225 0 0 1 1.624-2.241 2.743 2.743 0 0 1 .721-.095q4.722-.009 9.443 0a.755.755 0 0 1 .757 1.1.735.735 0 0 1-.7.392h-9.443c-.626 0-.907.279-.907.9v16.036a.777.777 0 0 0 .886.883h9.5a.731.731 0 0 1 .718.515.713.713 0 0 1-.29.833 1 1 0 0 1-.482.141q-4.791.012-9.582 0a2.23 2.23 0 0 1-2.247-2.294q-.002-4.055.002-8.113z" class="o-logout-icon" data-name="Tracé 175" transform="translate(-249.655 -150.376)"/>
                    <path id="Tracé_176" d="M394.04 270.448h-12.223a.751.751 0 1 1-.112-1.5h12.326c-.074-.081-.12-.133-.168-.182l-3.677-3.686a.74.74 0 0 1 .288-1.254.718.718 0 0 1 .761.2q.859.863 1.721 1.722l3.331 3.331a.765.765 0 0 1 .007 1.233l-5.109 5.109a.747.747 0 1 1-1.043-1.063q1.884-1.881 3.766-3.765c.035-.036.068-.075.132-.145z" class="o-logout-icon" data-name="Tracé 176" transform="translate(-375.788 -259.292)"/>
                </g>
            </svg>
            <div class="o-logout">{{$t('Logout')}}</div>
        </div>
    </div>
</template>

<script>

import ProductService from "../service/ProductService";

export default {
    data() {
        return {
            displayName: this.$auth.user.displayName,
            photoUrl: this.$auth.user.photoUrl,
            uploading: false,
            productService: null,
        };
    },
    computed: {
        displayNameComputed() {
            return this.displayName || this.$auth.user.email;
        },
        cssImage() {
            return {
                "background-image": `url("${this.photoUrl}")`,
            }
        },
    },
    created() {
        this.productService = new ProductService(this.$auth.accessToken);
        this.$auth.eventBus.$on("access_token_refreshed", () =>
            this.productService.updateToken(this.$auth.accessToken)
        );
  },
    destroyed() {
    },
    methods: {
        signout() {
            // this.exitSuperset();
            this.$auth.signOut();
        },
        exitSuperset() {
            this.$router.push('redirectlogout')
     },
    },
};
</script>

<style scoped>
</style>