import Vue from 'vue';
import App from './App.vue';
import PrimeComponent from './components/PrimeVue';
import KeycloakAuth from './auth/keycloakAuth';
import i18n from './i18n';
import VueClipboard from 'vue-clipboard2';
import PrimeVue from 'primevue/config';
import Router from 'vue-router';


import { hasAccess } from './auth/CheckRoles';

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(PrimeComponent);
Vue.use(PrimeVue);


Vue.prototype.$eventHub = new Vue(); // Global event bus

const keycloakAuth = new KeycloakAuth(
  { 
    url: process.env.VUE_APP_KEYCLOAK_URL + "auth", 
    realm: process.env.VUE_APP_KEYCLOAK_REALM, 
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT, 
    onLoad: "login-required",
  }
);

Vue.use(Router);

const _be = (client = '', required = [], mode = 'any') => {
  return (to, from, next) => {
      const roles = client ? keycloakAuth.state.user.roles[client]?.roles : keycloakAuth.state.user.allRoles;
      if (hasAccess(roles, required, mode)) {
        next();
      } else {
        next('/401');
      }
  };
};

const _root = () => {
  return () => {
    if (keycloakAuth.state.user.roles['realm-management']?.roles.includes('manage-users')) {
      return "/tickets";
    }
    else {
      return "/home"
    }
  };
};

const formatRoute = ({ path, id = null, show = false, external = null, required = null, mode = 'any', client = null, component = null, children = null, type = 'link' }) => {
  let t_r = {
      path,
      external
  };
  if (id) t_r.name = id;
  t_r.showInMenu = show;
  if (required) {
      t_r.requiredRoles = required;
      t_r.requiredMode = mode;
      t_r.beforeEnter = _be(client, required, mode);
      t_r.keycloakAccess = keycloakAuth;
  }
  if (client) t_r.requiredClient = client;
  if (component) t_r.component = component;
  if (children) t_r.children = children;
  t_r.type = type;
  return t_r;
}

const router = new Router({
  routes: [
    formatRoute({
        path: '/401',
        component: () => import('./pages/401.vue')
    }),
    formatRoute({
        path: '/2fa',
        id: "2fa",
        show: false,
        component: () => import('./pages/2FA.vue')
    }),
    formatRoute({
        path: '/account',
        id: 'Account',
        show: false,
        component: () => import('./pages/MyAccount.vue')
    }),
    formatRoute({
        path: '/home',
        id: 'Overview',
        show: true,
        required: ['dashboard-access'],
        component: () => import('./pages/home.vue')
    }),
    formatRoute({
      path: '/traceable',
      id: 'Traceable Unit History',
      show: true,
      required: ['dashboard-access'],
      component: () => import('./pages/search.vue')
    }),
    formatRoute({
        path: '/tickets',
        id: 'Ticket Management',
        show: true,
        required: ['dashboard-zp-ticket-management'],
        component: () => import('./pages/TicketManagement.vue')
    }),
    formatRoute({
      path: '/location',
      id: 'Moving Stock Vs Location',
      show: true,
      required: ['dashboard-access'],
      component: () => import('./pages/location.vue')
    }),

      formatRoute({
          path: '/administration',
          id: 'Administration',
          show: true,
          type: 'group',
          required: ['dashboard-zp-form-editor', 'dashboard-zp-manage-invitations', 'manage-users'],
          component: () => import("./pages/ChildView.vue"),
          children: [
            formatRoute({
              path: '/administration/editor',
              id: 'Form Editor',
              show: true,
              mode: 'all',
              required: ['dashboard-zp-form-editor'],
              component: () => import('./pages/FormEditor.vue')
            }),
            formatRoute({
              path: '/administration/invitations',
              id: 'Manage Invitations',
              show: true,
              required: ['dashboard-zp-manage-invitations'],
              component: () => import('./pages/Invitations.vue')
            }),
            formatRoute({
              path: '/administration/users',
              id: 'User Management',
              show: true,
              external: {
                  url: process.env.VUE_APP_KEYCLOAK_URL + 'auth/admin/' + process.env.VUE_APP_KEYCLOAK_REALM + '/console/#/realms/' + process.env.VUE_APP_KEYCLOAK_REALM + '/users',
                  target: "_blank"
              },
              required: ['manage-users'],
              client: 'realm-management',              
            }),
          ]
      }),
      { path: '/', redirect: _root() },
      {
          path: '*',
          component: () => import('./pages/404.vue')
      }
  ],
  scrollBehavior() {
      return { x: 0, y: 0 };
  }
});

function startVue() {
new Vue({
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app');
}

keycloakAuth.init(startVue);
