/**
 * Check for role membership.
 *
 * @param {Array} [roles=[]] The list of roles to check against
 * @param {Array} [required=[]] The list of roles required to be in roles
 * @param {String} [mode='any'] If set to 'any', returns true if any of the required roles is found. Otherwise, all roles are required. Defaults to 'any'
 * 
 * @returns {Boolean} True if required roles are in the roles list accoding to the mode, False otherwise
 */
exports.hasAccess = (roles= [], required = [], mode= 'any') => {
    if (!required.length) {
        return true;
    }

    if (mode.toLowerCase() === 'any') {
        return required.some(r => roles.includes(r));
    }
    else {
        return required.every(r => roles.includes(r));
    }
}