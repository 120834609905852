import Vue from 'vue';
import axios from "axios";

const base_url = process.env.VUE_APP_API_URL;

export default class ApiService {
	async getFromEp(ep, params) {
		await Vue.prototype.$auth.manageTokenRefresh();
		
		return axios
			.get(base_url + ep, {
				params: params,
				headers: {
					authorization: "Bearer " + Vue.prototype.$auth.accessToken
				}
			})
			.then(res => res.data)
			.catch(() => null);
	}

	async getFromKc(ep, params) {
		await Vue.prototype.$auth.manageTokenRefresh();
		return axios
			.get(process.env.VUE_APP_KEYCLOAK_URL + 'auth/admin/realms/' + process.env.VUE_APP_KEYCLOAK_REALM + '/' + ep, {
				params: params,
				headers: {
					authorization: "Bearer " + Vue.prototype.$auth.accessToken
				}
			})
			.then(res => res.data);
	}

	async postToEp(ep, body) {
		await Vue.prototype.$auth.manageTokenRefresh();
		return axios
			.post(
				base_url + ep,
				body,
				{
					headers: {
						authorization: "Bearer " + Vue.prototype.$auth.accessToken,
						'content-type': "application/json"
					}
				}
			)
			.then(res => res.data);
	}
}
