import Vue from 'vue';
import Keycloak from 'keycloak-js';

import ApiService from "../service/ApiService";


function KeycloakAuth(options) {
  this.options = options;
  this.keycloak = Keycloak(this.options);

  this.state = {
    updatingToken: false,
    isAuthenticated: false,
    accessToken: null,
    twofa: {value : false, status: false, method: 'email'},
    user: {
      providerId: null,
      uid: null,
      email: null,
      displayName: null,
      photoUrl: null,
      idToken: null,
      roles: null,
      allRoles: null,
      groups: []
    },
    eventBus: new Vue(),
    signOut: this.keycloak.logout,
    profile: this.keycloak.accountManagement,
    manageTokenRefresh: () => {
      if ((this.keycloak.tokenParsed.iat + 200) * 1000 < Date.now()) {
        if (!this.state.updatingToken) {
          this.state.updatingToken = true;
          this.keycloak.updateToken(-1).then(() => {
            this.state.eventBus.$emit('access_token_refreshed');
            this.state.updatingToken = false;
          })
          .catch((er) => Vue.$log.error("Error refreshing token : ", er));
        }
      }
    }
  }
  Vue.prototype.$auth = this.state;

  this.init = async function (callback) {
    // checkLoginIframe is set to false. This will make it impossible for the app to detect SSO sign-outs without reloading or token refresh, but is currently required
    // See : https://issues.redhat.com/browse/KEYCLOAK-12125
    const isAuthenticated = await this.keycloak.init({ onLoad: this.options.onLoad, checkLoginIframe: false, enableLogging: false });
    
    if (isAuthenticated) {
      this.state.lastRefresh = Date.now();
      const apiService = new ApiService(this.keycloak.token);
      const token = this.keycloak.tokenParsed;
      const extractClientRoles = (roles, client) => roles.concat(client.roles);
      this.state.isAuthenticated = true;
      this.state.accessToken = this.keycloak.token;
      this.state.user.providerId = token.iss;
      this.state.user.uid = token.preferred_username;
      this.state.user.sub = token.sub;
      this.state.user.email = token.email;
      this.state.user.displayName = `${token.given_name} ${token.family_name}`;
      this.state.user.roles = token.resource_access;
      this.state.user.roles.realm_access = token.realm_access;
      this.state.user.allRoles = Object.values(token.resource_access || []).reduce(extractClientRoles, token.realm_access.roles || []);

      let users = await apiService.getFromEp('user', {filters:JSON.stringify([{field: 'sub', value: token.sub}])})
      if (users.length) {
        this.state.twofa = {value : users[0].user.twofa, status: sessionStorage.twofa, method: users[0].user.notification.toLowerCase() || 'email', number: users[0].user.confirmedSms};
      }

      callback();
    }
  }
}

export default KeycloakAuth;
