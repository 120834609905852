import axios from 'axios';

var API_URL = process.env.VUE_APP_API_URL;

export default class ProductService {
    constructor(token) {
        this.updateToken(token);
    }

    updateToken(token) {
        this.token = token;
    }

    getFromEp(ep, params, err_cb = () => null) {
        return axios.get(`${API_URL}${ep}`, {
            params,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).then(res => res.data).catch(err_cb);
    }

    logOutSuperset() {
        var config = {
            method: 'get',
            url: 'https://34.134.189.46/logout',
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            credentials: "cross-origin"
        };

        return axios(config)
            .then(function (response) {
                return JSON.stringify(response.data);
            })
            .catch(function (error) {
                return error
            });

    }


}